import React from "react"
import { graphql } from "gatsby"
import { OurMerchants as PageQuery, Link, Meta, OurMerchantsSEO, Menu, SiteSettings, SiteBanner } from 'src/queries'
import Layout from 'src/components/layout'
import Slices from 'src/components/slices'
import {getPageData} from 'src/utilities/data'
import SEO from 'src/components/seo'

const OurMerchants = getPageData(({pageData, menuData, siteBannerData, siteSettings, pageContext}) => (
  <Layout pageContext={pageContext} menuData={menuData} siteBannerData={siteBannerData} siteSettings={siteSettings}>
    <SEO {...pageData} lang={pageContext.lang} />
    <Slices data={pageData.body}/>
  </Layout>
))

OurMerchants.fragments = [PageQuery, Link, Meta, OurMerchantsSEO, Menu, SiteSettings, SiteBanner]
export default OurMerchants
export const query = graphql`
  query ($lang: String) {
    prismic {
      allFor_merchants_15s(lang: $lang) { ...OurMerchants }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
      allSite_banners(lang: $lang) { ...SiteBanner }
    }
  }
`